<template>
    <div class="tachzuka">
        <div class="tachzuka-modal">
            <div class="tachzuka-modal-title">
                <h2>הודעת תחזוקת מערכת</h2>
            </div>
            <div class="tachzuka-modal-content">
                <img :src="maintenance">
            </div>
            <div class="tachzuka-modal-msg">
                <h1>המערכת בתחזוקה</h1>
                <h2>נעדכן על חידוש פעולות</h2>
            </div>
        </div>
    </div>
</template>

<script>
import maintenance from './imgs/maintenance.png'
export default {
    setup(){
        
        return{
            maintenance
        }
    }
}
</script>

<style scoped>
    .tachzuka{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--main);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        z-index: 5000;
    }
    .tachzuka-modal{
        width: 100%;
        max-width: 500px;
        height: 400px;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    .tachzuka-modal-title{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .tachzuka-modal-content{
        width: 100%;
        height: calc(100% - 120px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tachzuka-modal-content img{
        max-width: 100%;
        max-height: 100%;
    }
    .tachzuka-modal-msg{
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
</style>